import React, { useState, useEffect } from 'react';
import { useSlowFade } from 'React/custom_hooks';
import { TagTitle, Link, Wysiwyg, EnlaceAgrupador } from 'React/components';

import './styles.scss';
import { ReactComponent as ChevronIcon } from 'Icons/chevron_down-18.svg';
import { ReactComponent as IconDireccion } from 'Icons/direccion-16.svg';

const Acordeon = ({ tagTitle, moduleCTA, subModules, columns, anchor, managementTeam, managementTeamLower, labelSubdepartments, legend }) => {
    let moduleRef = React.createRef();
    let clickEl = React.createRef();
    useSlowFade(moduleRef);

    const [activeSection, setActiveSection] = useState();

    const toggleSection = (e) => {
        if (!managementTeam && !managementTeamLower) {
            e.currentTarget.parentElement.classList.toggle('is-active');
        }
    };

    return (
        <div
            ref={moduleRef}
            className={`module grid acordeon 
                ${columns === '1' ? 'single-column' : ''} 
                ${managementTeam === true ? 'acordeon--equipo-directivo' : ''} 
                ${managementTeamLower === true ? 'acordeon--equipo-directivo acordeon--equipo-directivo--lower' : ''} 
                ${subModules[0].image ? 'with-image' : ''}`
            } // if first item doesn't have image we assume this acordeon doesn't have images
        >
            {tagTitle && <TagTitle style="slow--y" title={tagTitle} />}

            {moduleCTA && typeof window != 'undefined' && useWindowWidth() >= 680 && (
                <Link className="module-cta" type={moduleCTA.type} path={moduleCTA.link} label={moduleCTA.caption + " "} />
            )}
            <div className="distribuidor-2-col-container grid">
                {subModules.map((item, idx) => {
                    const { image, title, content, downloads, groupLink, subModules, subtitle, link, isDirector, chevron, disabled } = item;

                    return (

                        <div
                            key={`data-section-${idx + 1}`}
                            data-is-active={activeSection === idx + 1}
                            className={`distribuidor-2-col-item slow--y ${subModules && subModules.length > 0 ? 'is-active' : ''}`}
                            ref={clickEl}
                        >
                            {image && (
                                <div className="image-container">
                                    <img src={image.url} alt={image.alt} title={image.title} />
                                </div>
                            )}
                            <div
                                className={`title-container ${!subModules ? '' : 'acordeon--disabled'}`}
                                onClick={(e) => toggleSection(e)}
                            >

                                <h2 className="title--s title">{title}</h2>
                                {subtitle && link && (
                                    <h3 className="title--xs">
                                        <a href={link.link} target={link.target}>{subtitle}</a>
                                        {isDirector &&
                                            <div className="icon-container">
                                                <IconDireccion className="hover-info" />
                                                <div className="info">
                                                   123 {legend}
                                                </div>
                                            </div>
                                        }
                                    </h3>
                                )}
                                {subtitle && !link && (
                                    <h3 className="title--xs">
                                        {subtitle}
                                        {isDirector &&
                                            <div className="icon-container">
                                                <IconDireccion className="hover-info" />
                                                <div className="info">
                                                    {legend}
                                                </div>
                                            </div>
                                        }
                                    </h3>
                                )}
                                {/*link && (
                                    <div>
                                        <Link type="arrow" label={link.caption + " "} path={link.link} />
                                    </div>
                                )*/}
                                    <div className="chevron">
                                        <ChevronIcon />
                                    </div>
                            </div>

                            {content && content.trim().startsWith('<') && (

                                <div className="main-content text-container">
                                    <Wysiwyg html={content} />
                                    {downloads && !groupLink && (
                                        <ul className="list list-downloads">
                                            {downloads.map((item, idx) => {
                                                return (
                                                    <li key={`text-p-${idx}`} className="link--download main-text-paragraph">
                                                        <Link type="download" path={item.file ? item.file : ''} label={item.name ? item.name : item.title} bytes={item.umbracoBytes} extension={item.umbracoExtension} />
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    )}
                                </div>
                            )}
                            {!content && downloads && !groupLink && (
                                <div className="main-content text-container">
                                    <ul className="list list-downloads">
                                        {downloads.map((item, idx) => {
                                            return (
                                                <li key={`text-p-${idx}`} className="link--download main-text-paragraph">
                                                    <Link type="download" path={item.umbracoFile ? item.umbracoFile : item.file} label={item.name ? item.name : item.title} bytes={item.umbracoBytes} extension={item.umbracoExtension} />
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            )}
                            {content && !content.trim().startsWith('<') && (
                                <div className="main-content text-container">
                                    <p className="lead-text body--m">{content}</p>
                                    {downloads && !groupLink && (
                                        <ul className="list list-downloads">
                                            {downloads.map((item, idx) => {
                                                return (
                                                    <li key={`text-p-${idx}`} className="link--download main-text-paragraph">
                                                        <Link type="download" path={item.umbracoFile} label={item.name ? item.name : item.title} bytes={item.umbracoBytes} extension={item.umbracoExtension} />
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    )}
                                </div>
                            )}
                            {subModules && subModules.length > 0 && (
                                <div className="main-content text-container">
                                    <ul>
                                        {subModules.map((frame, idx) => {
                                            return (
                                                <li className="body--s" key={`key-subdepartment-${idx}`}>
                                                    <strong>{frame.title}:</strong>{' '}
                                                    <span>
                                                        {frame.subtitle}
                                                        {frame.isDirector &&
                                                            <div className="icon-container">
                                                                <IconDireccion className="hover-info" />
                                                                <div className="info">
                                                                    {legend}
                                                                </div>
                                                            </div>
                                                        }
                                                    </span>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            )}

                            {/*downloads && !groupLink && (
                                <ul className="list list-downloads">
                                    {downloads.map((item, idx) => {
                                        return (
                                            <li key={`text-p-${idx}`} className="slow--y link--download main-text-paragraph">
                                                <Link type="download" path={item.umbracoFile} label={item.name} bytes={item.umbracoBytes} extension={item.umbracoExtension} />
                                            </li>
                                        );
                                    })}
                                </ul>
                            )*/}
                            {/*downloads && groupLink && (
                                <ul className="list list-downloads">
                                    <EnlaceAgrupador links={downloads} box_label={groupLink} />
                                    </ul>
                            )*/}
                        </div>
                    );
                })}
                <span className="custom-anchor" id={anchor} />
            </div>
            {moduleCTA && typeof window != 'undefined' && useWindowWidth() < 680 && (
                <Link className="module-cta" type={moduleCTA.type} path={moduleCTA.link} label={moduleCTA.caption + " "} target={moduleCTA.target} />
            )}
            {legend && managementTeamLower &&(
                <p className="legend link slow--y">
                    <IconDireccion />
                    {legend}
                </p>
            )}
        </div>
    );
};

const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    return windowWidth;
};

export default Acordeon;
