import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as IconArrowRight } from 'Icons/iconarrowright.svg';
import { ReactComponent as IconArrowRightLong } from 'Icons/ArrowLong.svg';
import { ReactComponent as IconDownload } from 'Icons/download.svg';
import { ReactComponent as IconPlus } from 'Icons/iconplus.svg';
import { ReactComponent as IconExternal } from 'Icons/linkexternal.svg';
import { ReactComponent as IconCalendar } from 'Icons/calendar.svg';

import uiContext from 'Data/_uiContext';

const _Link = ({ type, fileParams, bytes, extension, label, path, children, className, subsection, target }) => {
    // the following path prefix needs to be modified to match actual production paths
    const href = `${process.env.NODE_ENV === 'production' ? '/dist' : ''}${path}`;
    const context = useContext(uiContext);

    const toggleSubsectionVisibility = () => {
        if (subsection) {
            const { order } = subsection;
            context.updateDesktopNavSubsState(order);
        }
        if (['/proyectos', '/actualidad', '/about-us', '/our-approach', '/projects', '/news'].includes(path)) {
            context.updateDesktopNavSubsState(null);
        }
    };

    function bytesToSize(bytes) {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }

    if (type === 'arrow' || type === 'Internal' || type === 'Content') {
        const arrow = !label && !children ? <IconArrowRightLong className="cta-single" /> : <IconArrowRight className="cta-single"/>;

        return (
            <a className={`link link--icon icon--red ${className}`} href={href} target={target ? target : ''}>
                <span>
                    {label}
                    {children}
                    {arrow}
                </span>
            </a>
        );
    }
    if (type === 'arrowLong') {
        return (
            <a className={`link link--icon icon--red ${className}`} href={href} target={target ? target : ''}>
                <span>
                    {label}
                    {children}
                    <IconArrowRightLong />
                </span>
            </a>
        );
    }
    if (type === 'download' || type === 'Media' || type === 'File') {
        return (
            <a className={`link link--icon icon--red link--download ${className}`} href={href} target='_blank'>
                <IconDownload />
                <span>
                    {label}
                    {children}
                </span>
                <p className="link--download-type">{bytes ? extension + ' ' + bytesToSize(bytes) : 'PDF 814KB'}</p>
            </a>
        );
    }
    if (type === 'back') {
        return (
            <a className={`link link--icon icon--red link--back ${className}`} href={href}>
                <IconArrowRight />
                <span>
                    {label}
                    {children}
                </span>
            </a>
        );
    }
    if (type === 'subscribe') {
        return (
            <a className={`link link--icon link--subscribe`} to="#">
                <IconCalendar />
                <span>
                    {label}
                    {children}
                </span>
            </a>
        );
    }
    if (type === 'lightbox') {
        return (
            <a className={`link link--icon icon--red link--lightbox ${className}`} href="#">
                <span>
                    {label}
                    {children}
                    <IconPlus/>
                </span>
            </a>
        );
    }
    if (type === 'External') {
        return (
            <a className={`link link--icon icon--red link--external ${className}`} href={href} target={target ? target : ''}>
                <span>
                    {label}
                    {children}
                    <IconExternal />
                </span>
            </a>
        );
    } else {
        return (
            <a className={className} href={href} onMouseOver={toggleSubsectionVisibility} target={target ? target : ''}>
                {label}
                {children}
            </a>
        );
    }
};

export default _Link;
