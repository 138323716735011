import React from 'react';
import { Link } from 'React/components';
import IconsMap from './_iconsMap';

import { useSlowFade } from 'React/custom_hooks';

import './styles.scss';
import { TagTitle } from '..';

const SolucionesBotonera = ({ title, body, cta, activeSolutions, anchor }) => {
    const moduleRef = React.createRef();

    useSlowFade(moduleRef);

    return (
        <div ref={moduleRef} className="module grid soluciones-botonera" >
            <div className="grid-container grid full-width soluciones-botonera__header" id="soluciones-botonera">
                <h2 className="title title--l slow--y" id={anchor}>{title}</h2>
                <p className="body body--m slow--y">{body}
                    {cta &&
                        <span className="cta grid-container slow--y mobile-hidden">
                            <Link type="arrow" label={cta.caption} path={cta.link} target={cta.target} />
                        </span>
                    }
                </p>
                {cta &&
                    <div className="cta grid-container slow--y mobile-visible">
                        <Link type="arrow" label={cta.caption} path={cta.link} target={cta.target} />
                    </div>
                }
            </div>
            <div className="grid-container grid full-width soluciones-botonera__content">
                <ul className="grid-container">
                    {activeSolutions.map((solution, idx) => {
                        const { icon, menuText, _contentPath } = solution;

                        return (
                            <li className="title--s slow--y" key={`solution-button-${idx}`}><a href={_contentPath}>{IconsMap[icon]}{menuText}</a></li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default SolucionesBotonera;
