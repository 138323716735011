import React, { useState } from 'react';
import { useSlowFade } from 'React/custom_hooks';

import { rndKeyGen } from 'React/helpers';
import { Link, Lightbox, Wysiwyg } from 'React/components';
import './styles.scss';

const DevelopmentGoals = ({ title, description, cta, subModules, anchor, culture }) => {
    let moduleRef = React.createRef();
    useSlowFade(moduleRef);
    const [visibleLightbox, setVisibleLightbox] = useState(null);

    const goalsArray = subModules.reduce((acc, el, idx) => {
        acc[el.number] = el;
        return acc;
    }, []);

    const goalsWithLightbox = goalsArray.filter((goal) => goal.content !== '');

    const handleGoalClick = (number) => {
        const clickedGoal = goalsArray[number];
        if (clickedGoal && clickedGoal.content !== '') {
            setVisibleLightbox(number);
        }
    };

    if (typeof window !== 'undefined') {
        const goalsImagesUrlPrefix = culture === 'es' ?
            '/static/development_goals_images/Goal_' : '/static/development_goals_images_en/Goal_';

        return (
            <>
                <div ref={moduleRef} id="devGoals" className="module grid development-goals">
                    <h2 className="title title--l slow--y">{title}</h2>
                    <p className="description body--m slow--y">
                        {description}
                        {cta && (
                            <p className="slow--y">
                                <Link className="module--cta" type="arrow" path={cta.link} target={cta.target}>
                                    {cta.caption}
                                </Link>
                            </p>
                        )}

                    </p>
                    <span className="custom-anchor" id={anchor} />
                    <div className="goals-buttons slow--y">
                        {Array.from(new Array(18), (goal, idx) => {
                            const currentGoal = goalsArray[idx];
                            if (currentGoal && currentGoal.content === '') {
                                // external link
                                if (currentGoal && currentGoal.cta && currentGoal.cta.type === 'External') {
                                    return (
                                        <div className="goal-button" key={rndKeyGen()} data-is-active={currentGoal !== undefined}>
                                            <Link path={currentGoal.cta.link} target={currentGoal.cta.target}>
                                                <img src={goalsImagesUrlPrefix + idx + '.png'} key={rndKeyGen()} />
                                            </Link>
                                        </div>
                                    );
                                }
                                // internal link
                                if (currentGoal && currentGoal.cta && currentGoal.cta.type === 'Internal') {
                                    return (
                                        <div className="goal-button" key={rndKeyGen()} data-is-active={currentGoal !== undefined}>
                                            <Link path={currentGoal.cta.link} target={currentGoal.cta.target}>
                                                <img src={goalsImagesUrlPrefix + idx + '.png'} key={rndKeyGen()} />
                                            </Link>
                                        </div>
                                    );
                                }

                            }
                            // lightbox
                            return (
                                <div className="goal-button" key={rndKeyGen()} data-is-active={currentGoal !== undefined}>
                                    <img
                                        data-is-active={currentGoal !== undefined}
                                        onClick={() => handleGoalClick(idx)}
                                        src={goalsImagesUrlPrefix + idx + '.png'}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
                {goalsWithLightbox.map((goal, idx) => {
                    return (
                        <Lightbox
                            key={rndKeyGen()}
                            isVisible={visibleLightbox === goal.number}
                            closeMethod={() => setVisibleLightbox(null)}
                        >
                            <Wysiwyg html={goal.content} />
                        </Lightbox>
                    );
                })}
            </>
        );
    }
    else {
        return (<>
            <div ref={moduleRef} id="devGoals" className="module grid development-goals">
                <h2 className="title title--l slow--y">{title}</h2>
            </div>
        </>);
    }

};

export default DevelopmentGoals;
