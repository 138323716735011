import React from 'react';
import { Link, EnlaceAgrupador } from 'React/components';
import { useFade } from 'React/custom_hooks';
import { replaceAll, bytesToSize } from 'React/helpers';

import 'Styles/_texts.scss';
import './style.scss';

const Wysiwyg = ({ html, withMargin, subModules, anchor, isAnimated }) => {

    const moduleRef = React.createRef();
    //const moduleRef1 = React.createRef();

    useFade(moduleRef);

    if (html) {
        html = replaceAll(html, "https://accionacorp.blob.core.windows.net", "https://mediacdn.acciona.com");
        if (html.includes('src="/media/')) html = replaceAll(html, 'src="/media/', 'src="https://mediacdn.acciona.com/media/');
        if (html.includes('href="/media/')) html = replaceAll(html, 'href="/media/', 'href="https://mediacdn.acciona.com/media/');
        //if (html.includes("/media/") && !html.includes("https://mediacdn.acciona.com/media/") && !html.includes("acciona.com/media/")) {
        //    html = replaceAll(html, "/media/", "https://mediacdn.acciona.com/media/");
        //}
        return (
            <div ref={moduleRef} className="module module-wysiwyg" style={{ marginBottom: withMargin ? '' : 0 }}>

                {html &&
                    <div className="grid">
                        <div className={`text-container ${isAnimated ? 'slow' : ''}`} dangerouslySetInnerHTML={{ __html: html }} />
                    </div>
                }

            </div>
        );
    }
    else {
        return (
            <div ref={moduleRef} className="module module-wysiwyg">
                <div className="grid">
                    <span className="custom-anchor-small" id={anchor} />
                    {subModules.map((item, idx) => {
                        if (item._documentType === 'ModuleWysiwygItemText') {
                            var body = item.body;
                            var notAnimated = item.disableAnimationOnScroll;

                            if (body.includes('src="/media/')) body = replaceAll(body, 'src="/media/', 'src="https://mediacdn.acciona.com/media/');
                            if (body.includes('href="/media/')) body = replaceAll(body, 'href="/media/', 'href="https://mediacdn.acciona.com/media/');
                            //if (body.includes("/media/") && !body.includes("https://mediacdn.acciona.com/media/") && !body.includes("acciona.com/media/")) {
                            //    body = replaceAll(body, "/media/", "https://mediacdn.acciona.com/media/");
                            //}
                            return (
                                <div className={`text-container ${notAnimated === true ? '' : 'slow'}`} key={`wy-text-${idx}`} dangerouslySetInnerHTML={{ __html: body }}></div>
                            );
                        }
                        if (item._documentType === 'ModuleWysiwygItemLinks') {
                            if (item.links) {
                                return (
                                    <div className="text-container slow" key={`wy-link-${idx}`}>
                                        {item.links.map((link, idx) => {
                                            return (
                                                <Link path={link.link} label={link.caption} type={link.type} target={link.target} />
                                            );
                                        })}
                                    </div>
                                );
                            }
                        }
                        if (item._documentType === 'ModuleWysiwygItemDownloads') {
                            if (item.downloads && !item.groupLink) {
                                return (
                                    <div className="text-container slow">
                                        {
                                            item.downloads.map((download, idx) => {
                                                return (
                                                    <div className="link--download" key={`wy-download-${idx}`}>
                                                        <a target="_blank" className="icon--red link module--cta" href={download.umbracoFile ? download.umbracoFile : download.file}><svg width="18" height="18"><path d="M2.5 12v3.5h13V12h1v4.5h-15V12h1zm7-12v11.167l3.382-3.381.707.707-4.596 4.596-4.597-4.596.708-.707 3.395 3.395L8.5 0h1z" fill="#1F140F" fill-rule="evenodd"></path></svg><p>{download.name ? download.name : download.title}</p><p className="link--download-type">{download.umbracoExtension ? download.umbracoExtension.toUpperCase() : 'PDF'} {download.umbracoBytes ? bytesToSize(download.umbracoBytes) : '814KB'}</p></a>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                );
                            }
                            else if (item.downloads && item.groupLink) {
                                return (
                                    <div className="text-container slow">
                                        <ul className="enlace-agrupador">
                                            <li className="slow">
                                                <EnlaceAgrupador links={item.downloads} box_label={item.groupLink} />
                                            </li>
                                        </ul>
                                        {/*<div className="text-container slow">
                                        <EnlaceAgrupador links={item.downloads} box_label={item.groupLink} />
                                    </div>*/}
                                    </div>
                                );
                            }
                        }
                        if (item._documentType === 'ModuleWysiwygItemImage') {
                            if (item.isFeatured) {
                                return (
                                    <div className="image-featured slow">
                                        {item.image && <div className="image-content"><img src={item.image.url} alt={item.image.alt ? item.image.alt : ''} title={item.image.title} /></div>}
                                        {item.caption &&
                                            <div className="image-caption">{item.caption}</div>
                                        }
                                    </div>
                                );
                            } else {
                                if (item.column === 'right') {
                                    return (
                                        <div className="image-container image-container--right slow">
                                            <div className="content slow">
                                                {item.title &&
                                                    <h3>{item.title}</h3>
                                                }
                                                {item.text &&
                                                    <div className="text-container" dangerouslySetInnerHTML={{ __html: item.text }}></div>
                                                }
                                            </div>
                                            {item.image && <div className="image slow"><img src={item.image.url} alt={item.image.alt ? item.image.alt : ''} title={item.image.title} /></div>}
                                        </div>
                                    );
                                }
                                else {
                                    return (
                                        <div className="image-container slow">
                                            {item.image && <div className="image slow"><img src={item.image.url} alt={item.image.alt ? item.image.alt : ''} title={item.image.title} /></div>}
                                            <div className="content slow">
                                                {item.title &&
                                                    <h3>{item.title}</h3>
                                                }
                                                {item.text &&
                                                    <div className="text-container" dangerouslySetInnerHTML={{ __html: item.text }}></div>
                                                }
                                            </div>
                                        </div>
                                    );
                                }
                            }
                        }
                        if (item._documentType === 'ModuleWysiwygItemQuote') {
                            return (
                                <div className="blockquote with-quote slow" key={`wy-quote-${idx}`}><blockquote>{item.blockquote}</blockquote></div>
                            );
                        }
                    })}
                </div>
            </div>
        );
    }

};

export default Wysiwyg;
