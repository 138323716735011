import React, { useState, useEffect } from 'react';
import { ReactComponent as IconArrowRightLong } from 'Icons/ArrowLong.svg';
import { useSliderSwipe, useObserver } from 'React/custom_hooks';
//import { TimelineMax, TweenMax } from 'gsap/all';
import { PropTypes as pt } from 'prop-types';

import './style.scss';

import { TagTitle, InputRangeSlider, Link } from 'React/components';

const CifrasSlider = ({ title, subModules, module_title, lead_text, module_description, module_cta, anchor }) => {
    const [activeFrame, setActiveFrame] = useState(1);

    const imagesContainerWidth = { width: `${100 * (subModules.length + 1)}vw` };
    const cifrasSlider = React.createRef();
    const cifrasRef = React.createRef();
    const prevButton = React.createRef();
    const nextButton = React.createRef();

    const nextHover = () => {
        nextButton.current.classList.add('is-animated');
        prevButton.current.classList.remove('is-animated');
    };

    const prevHover = () => {
        prevButton.current.classList.add('is-animated');
        nextButton.current.classList.remove('is-animated');
    };

    const removeHover = () => {
        prevButton.current.classList.remove('is-animated');
        nextButton.current.classList.remove('is-animated');
    };

    const nextFrame = () => {
        if (activeFrame < subModules.length) {
            setActiveFrame(activeFrame + 1);
        }
    };

    const prevFrame = () => {
        if (activeFrame > 1) {
            setActiveFrame(activeFrame - 1);
        }
    };

    if (subModules.length > 1) {
        useSliderSwipe(cifrasSlider, setActiveFrame, subModules.length);
    }

    if (typeof window !== 'undefined') {
        const [observer, setElements, entries] = useObserver({
            root: null,
            threshold: 0.5,
        });

        const tl = new TimelineMax({ delay: 0, repeat: 0 });

        useEffect(() => {
            // añadimos la clase slow--y sólo al primer elemento, ya que si no haría trigger de la animación cada vez que cambiamos de frame.
            cifrasRef.current.firstElementChild.querySelector('.number-inner').classList.add('slow--y');
            cifrasRef.current.firstElementChild.querySelector('.title--xs').classList.add('slow--y');
            // guardamos los elementos que queremos observar
            const elements = cifrasSlider.current.querySelectorAll('.slow--y');
            // seteamos tweenmax con opacidad 0 para luego aplicar opacidad 1 cuando los elementos sean visibles al viewport.
            TweenMax.set(elements, { opacity: 0, y: 90 });
            setElements(elements);
        }, [setElements]);

        useEffect(() => {
            entries.forEach((entry) => {
                // buscamos los elementos que son visibles y entonces aplicamos la animación.
                if (entry.isIntersecting) {
                    let lazyItem = entry.target;
                    tl.to(lazyItem, 1, { opacity: 1, y: 0 }, 0.2);
                    observer.unobserve(lazyItem);
                }
            });
        }, [entries, observer, TimelineMax]);
    }
    return (
        <div className="module grid cifras-slider slow--y" data-active-frame={activeFrame} ref={cifrasSlider}>
            <TagTitle style="slow--y" title={title} />
            {module_title && <h3 className="module_title title--l slow--y">{module_title}</h3>}
            {lead_text && <h4 className="lead_text body--l slow--y">{lead_text}</h4>}
            {module_description && (
                <div className="module_description body--m slow--y">
                    {module_description}
                    {module_cta && (
                        <div className="module_cta slow--y">
                            <Link path={module_cta.link} type="arrow" target={module_cta.target}>
                                {module_cta.caption}
                            </Link>
                        </div>
                    )}
                </div>
            )}
            {subModules.length > 1 && (
                <>
                    <button
                        onMouseOver={prevHover}
                        onMouseOut={removeHover}
                        className="ghost-frame-button ghost-frame-button--left"
                        onClick={prevFrame}
                    ></button>
                    <button
                        onMouseOver={nextHover}
                        onMouseOut={removeHover}
                        className="ghost-frame-button ghost-frame-button--right"
                        onClick={nextFrame}
                    ></button>
                </>
            )}
            <div ref={cifrasRef} className="cifras-container" style={imagesContainerWidth}>
                {subModules.map((frame, idx) => {
                    const { value, unit, caption } = frame;
                    return (
                        <div className="data-point" key={`data-point-${idx}`}>
                            <h3 className="number cifras--xl">
                                <div className="number-container">
                                    <div className="number-inner">
                                        {value}
                                        {unit && <small>{unit}</small>}
                                    </div>
                                    <p className="title--xs short-descritpion">{caption}</p>
                                </div>
                            </h3>
                        </div>
                    );
                })}
            </div>
            <span className="custom-anchor" id={anchor} />
            {subModules.length > 1 ? (
                <>
                    <InputRangeSlider
                        changeHandler={setActiveFrame}
                        noIndicator
                        framesCount={subModules.length}
                        activeFrame={parseInt(activeFrame)}
                        description={module_description}
                        frameDescription={subModules[activeFrame - 1].description}
                    />
                    <div className="slider-controls slow--y">
                        <button ref={prevButton} className="prev-button" onClick={prevFrame}>
                            <IconArrowRightLong />
                        </button>
                        <button ref={nextButton} className="next-button" onClick={nextFrame}>
                            <IconArrowRightLong />
                        </button>
                    </div>
                </>
            ) : (
                    <hr className="separator slow--y" />
                )}
        </div>
    );
};

export default CifrasSlider;
