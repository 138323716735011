import React, { useEffect } from 'react';
import Hammer from '@egjs/hammerjs';

function useSliderSwipe(containerRef, frameSetMethod, framesLength, screenSizeLimit = 99999) {
    // change frame position on swipe left or right
    useEffect(() => {
        if (screenSizeLimit <= window.innerWidth) {
            return;
        }

        const sliderEl = containerRef.current;
        const swipeManager = new Hammer.Manager(sliderEl);
        // if the direction is not set to horizontal it can block page scroll on touch screens
        const SliderSwipe = new Hammer.Swipe().set({
            direction: Hammer.DIRECTION_HORIZONTAL,
        });

        swipeManager.add(SliderSwipe);

        const framesList = sliderEl.querySelectorAll('.data-point');

        framesList.forEach((frame, idx) => {
            if (idx + 1 === parseInt(sliderEl.dataset.activeFrame)) {
                frame.classList.remove('prev-frame');
                frame.classList.remove('next-frame');
                frame.classList.add('frame-active');

                if (frame.nextElementSibling != null) {
                    frame.nextElementSibling.classList.add('next-frame');
                }

                if (frame.previousElementSibling != null) {
                    frame.previousElementSibling.classList.add('prev-frame');
                }

                if (frame.nextElementSibling != null) {
                    frame.nextElementSibling.classList.add('next-frame');
                } else {
                    frame.classList.remove('next-frame');
                }
            } else {
                frame.classList.remove('frame-active');
                frame.classList.remove('prev-frame');
            }
        });

        let nextButtons = sliderEl.querySelectorAll('.next-frame');
        let prevButtons = sliderEl.querySelectorAll('.prev-frame');

        if (nextButtons.length > 1) {
            nextButtons.forEach((el) => {
                el.addEventListener('click', () => {
                    if (currentFrame < framesLength) {
                        frameSetMethod(currentFrame + 1);
                    }
                });
            });
        }

        if (prevButtons.length > 1) {
            prevButtons.forEach((el) => {
                el.addEventListener('click', () => {
                    if (currentFrame > 1) {
                        frameSetMethod(currentFrame - 1);
                    }
                });
            });
        }

        const currentFrame = parseInt(sliderEl.dataset.activeFrame);
        const sliderControls = sliderEl.querySelector('.slider-controls');
        const nextFrame = sliderEl.querySelector('.next-frame');
        const prevFrame = sliderEl.querySelector('.prev-frame');
        const ghostLeft = sliderEl.querySelector('.ghost-frame-button--left');
        const ghostRight = sliderEl.querySelector('.ghost-frame-button--right');

        if (currentFrame > 1 && ghostLeft != null) {
            ghostLeft.classList.remove('is-disabled');
        } else {
            if (ghostLeft != null) {
                ghostLeft.classList.add('is-disabled');
            }
        }

        if (currentFrame === framesLength && ghostRight != null) {
            ghostRight.classList.add('is-disabled');
        } else {
            if (ghostRight != null) {
                ghostRight.classList.remove('is-disabled');
            }
        }

        // if (currentFrame === framesLength && ghostRight != null) {
        //   sliderEl.querySelector('.ghost-frame-button--right').classList.add('is-disabled');
        // }

        if (currentFrame > framesLength && nextFrame != null) {
            sliderEl.querySelector('.next-frame').classList.add('is-disabled');
        }

        if (currentFrame <= 1 && prevFrame != null) {
            sliderEl.querySelector('.prev-frame').classList.add('is-disabled');
        }

        if (currentFrame === framesLength && sliderControls != null) {
            sliderControls.querySelector('.next-button').classList.add('is-disabled');
        } else {
            if (sliderControls != null) {
                sliderControls.querySelector('.next-button').classList.remove('is-disabled');
            }
        }

        if (currentFrame <= 1 && sliderControls != null) {
            sliderControls.querySelector('.prev-button').classList.add('is-disabled');
        } else {
            if (sliderControls != null) {
                sliderControls.querySelector('.prev-button').classList.remove('is-disabled');
            }
        }

        if (sliderEl.querySelector('.next-frame') != null) {
            sliderEl.querySelector('.next-frame').addEventListener('click', () => {
                if (currentFrame < framesLength) {
                    frameSetMethod(currentFrame + 1);
                }

                if (currentFrame > 1) {
                    frameSetMethod(currentFrame + 1);
                }
            });
        }

        swipeManager.on(
            'swipeleft swiperight',
            (event) => {
                const direction = event.offsetDirection;
                const currentFrame = parseInt(sliderEl.dataset.activeFrame);
                if (direction === 2 && currentFrame < framesLength) {
                    frameSetMethod(currentFrame + 1);
                } else if (direction === 4 && currentFrame > 1) {
                    frameSetMethod(currentFrame - 1);
                }
            },
            []
        );

        return () => {
            // remove listener and kill manager when component is removed
            swipeManager.remove(SliderSwipe);
            swipeManager.destroy();
        };
    });
}

export default useSliderSwipe;
