import React, { useRef } from 'react';

import { useStickyHeader } from 'React/custom_hooks';
import { Link } from 'React/components';

import './HeaderLanding.scss';
import LogoFull from 'Images/logos/logo_full.svg';

const HeaderLandingOwnDomain = ({ stickyHeader = true, logo, hrefLogo, homeLink}) => {
    const headerRef = useRef();

    // STICKY HEADER ON SCROLL UP
    useStickyHeader(headerRef, stickyHeader);

    return (
        <>
            {!logo && (
                <header className="header-landing" ref={headerRef} id="header">
                    <Link path={homeLink} className="logo-home-link">
                        <img className="logo" src={LogoFull} alt="Logo Acciona" title="Logo Acciona" />
                    </Link>
                </header>
            )}
            {logo && (
                <header className="page-header grid header-landing header-landing--logo" ref={headerRef} id="header">
                    <Link path={homeLink} className="logo-home-link">
                        <img className="logo" src={LogoFull} alt="Logo Acciona" title="Logo Acciona" />
                    </Link>
                    <Link path={hrefLogo ? hrefLogo.link : '/'} className="logo-home-right">
                        <img
                            className="logo"
                            src={logo.url}
                            alt={logo.name}
                            title={logo.title}
                        />
                    </Link>
                </header>
            )}
        </>
    );
};

export default HeaderLandingOwnDomain;
