// button.component.js
import React, { useState } from 'react';
import './styles.scss';
import { CheckboxGroup, TagTitle } from 'React/components';
import { ReactComponent as IconSearch } from 'Icons/searchlense.svg';
import { ReactComponent as IconShow } from 'Icons/filteropen-16.svg';
import { ReactComponent as IconHide } from 'Icons/filterclose-16.svg';
import { useFade } from '../../custom_hooks';

const BuscadorFiltros = ({ type, title, yearFilter, monthFilter, solutionFilter, searchFilter,
    yearsCombo, monthsCombo, solutionsCombo, yearSelected, hideCombo,
    areasCombo, divisionsCombo, countriesCombo,
    relationsFilter, countrySelected, divisionSelected,
    areaSelected, jobTypeSelected, getListOffers,
    labelAllcountries, labelAllsolutions, labelAllareas,
    labelEligeOpcion, labelCountry, labelSolution,
    labelArea, labelVacancieType, labelProfesional,
    labelGraduates, labelBecas, labelReports, labelPresentations, filterTerritory,
    labelMostrarFiltros, labelOcultarFiltros, culture
}) => {

    const [filterState, setFilterState] = useState(false);
    const [filterAreaState, setFilterAreaState] = useState('');
    const [filterTerritoryState, setFilterTerritoryState] = useState('');
    const _btnIcon = () => (filterState === true ? <IconShow /> : <IconHide />);
    const _btnLabel = () => (filterState === true ? labelMostrarFiltros : labelOcultarFiltros);

    const setFilterArea = (event) => {
        setFilterAreaState(event.target.value);
        filterTerritory(event.target.value);
    };

    const setFilterTerritory = (event) => {
        setFilterTerritoryState(event.target.value);
    };

    let moduleRef = React.createRef();

    useFade(moduleRef);

    let yearName = 'AÑO';
    let monthName = 'MES';
    let solutionName = 'Negocio';
    if (culture !== 'es') {
        yearName = 'YEAR';
        monthName = 'Month';
        solutionName = 'Business';
    }
    
    if (type === 'noticias') {
        return (
            <div ref={moduleRef} className="slow module grid  buscadorfiltros--noticias module-buscadorfiltros">
                <div className="grid-container">
                    {title && <TagTitle title={title} />}
                    {!hideCombo &&
                        <form className="filters-container">
                            <div className={`select-container ${monthsCombo ? ' no-margin-right' : ''}`}>
                                <div className="select">
                                    <select name="slct" id="slct" onChange={yearFilter}>
                                        <option value="" selected> {yearName} </option>
                                        {yearsCombo.map((year, idx) => {
                                            return (
                                                <option value={year}>{year}</option>
                                            );
                                        })}
                                    </select>
                                    <label className="taglabel"> {yearName} </label>
                                </div>
                            </div>
                        {monthsCombo && yearSelected &&
                                <div className="select-container no-margin-right">
                                    <div className="select">
                                        <select name="slct" id="slct" onChange={monthFilter} >
                                            <option value="" selected> {monthName} </option>
                                            {monthsCombo.map((month, idx) => {
                                                return (
                                                    <option value={month}>{month}</option>
                                                );
                                            })}
                                        </select>
                                        <label className="taglabel"> {monthName} </label>
                                    </div>
                                </div>
                            }
                            {solutionsCombo &&
                                <div className="select-container">
                                    <div className="select">
                                        <select name="slct" id="slct" onChange={solutionFilter}>
                                            <option value="" selected> {solutionName} </option>
                                            {solutionsCombo.map((solution, idx) => {
                                                return (
                                                    <option value={solution.value}>{solution.name}</option>
                                                );
                                            })}
                                        </select>
                                        <label className="taglabel"> {solutionName} </label>
                                    </div>
                                </div>
                            }
                        </form>
                    }
                </div>
            </div>
        );
    }
    if (type === 'informes') {
        return (
            <div className={`grid module-buscadorfiltros  module-buscadorfiltros--informes`}>
                <div className="grid-container">
                    <form className="filters-container">
                        <div className="checkbox-filter">
                            <CheckboxGroup check1={labelReports} check2={labelPresentations} event={searchFilter} />
                        </div>
                    </form>
                </div>
            </div>
        );
    }
    if (type === 'empleo') {
        return (
            <div
                ref={moduleRef}
                className={`grid buscadorfiltros--ofertas module-buscadorfiltros ${filterState === true ? 'hide' : 'show'}`}
            >
                <div className="grid-container">
                    <button id="button-filters" onClick={() => setFilterState((oldState) => !oldState)}>
                        <span className="taglabel slow">
                            <_btnIcon /> <_btnLabel />
                        </span>
                    </button>
                </div>
                <div className="grid-container">
                    <form className="filters-container">
                        <div className="select-container slow">
                            <div className="select">
                                <select name="slct" id="countries" onChange={(e) => relationsFilter("countries", e)} value={countrySelected}>
                                    <option value="">
                                        {labelAllcountries}
                                    </option>
                                    {countriesCombo.map((country, idx) => {
                                        return (
                                            <option value={country}>{country}</option>
                                        );
                                    })}
                                </select>
                                <label className="taglabel">{labelCountry}</label>
                            </div>
                            <div className="select">
                                <select name="slct" id="divisions" onChange={(e) => relationsFilter("divisions", e)} value={divisionSelected}>
                                    <option value="">
                                        {labelAllsolutions}
                                    </option>
                                    {divisionsCombo.map((division, idx) => {
                                        return (
                                            <option value={division}>{division}</option>
                                        );
                                    })}
                                </select>
                                <label className="taglabel">{labelSolution}</label>
                            </div>
                            <div className="select">
                                <select name="slct" id="areas" onChange={(e) => relationsFilter("areas", e)} value={areaSelected}>
                                    <option value="">
                                        {labelAllareas}
                                    </option>
                                    {areasCombo.map((area, idx) => {
                                        return (
                                            <option value={area}>{area}</option>
                                        );
                                    })}
                                </select>
                                <label className="taglabel">{labelArea}</label>
                            </div>
                            <div className="select">
                                <select name="slct" id="jobTypes" onChange={(e) => relationsFilter("jobType", e)} value={jobTypeSelected}>
                                    <option value="">
                                        {labelEligeOpcion}
                                    </option>
                                    <option value="Profesional">{labelProfesional}</option>
                                    <option value="Graduados">{labelGraduates}</option>
                                    <option value="Becas">{labelBecas}</option>
                                </select>
                                <label className="taglabel">{labelVacancieType}</label>
                            </div>
                        </div>
                        <button type="button" className="search-btn slow" onClick={(e) => getListOffers(1)}>
                            <IconSearch />{' '}
                        </button>
                    </form>
                </div>
            </div>
        );
    }
    if (type === 'equipo') {
        return (
            <div ref={moduleRef} className={`grid buscadorfiltros--ofertas module-buscadorfiltros buscadorfiltros--equipo`}>
                <div className="grid-container">
                    <div className="module-title">{title && <TagTitle title={title} />}</div>
                    <form className="filters-container">
                        <div className="select-container slow">
                            <div className="select">
                                <select name="slct" id="slct" onChange={setFilterArea}>
                                    <option value="" selected>
                                        Todas las áreas
                  </option>
                                    {areasCombo.map((area, idx) => {
                                        return (
                                            <option value={area.value}>{area.name}</option>
                                        );
                                    })}
                                </select>
                                <label className="taglabel">Área/Perfil</label>
                            </div>
                            <div className="select">
                                <select name="slct" id="slct" onChange={setFilterTerritory}>
                                    <option value="" selected>
                                        Todos los territorios
                  </option>
                                    {yearsCombo.map((territory, idx) => {
                                        return (
                                            <option value={territory.value}>{territory.name}</option>
                                        );
                                    })}
                                </select>
                                <label className="taglabel">Territorio</label>
                            </div>
                        </div>
                        <button type="button" className="search-btn slow" onClick={() => searchFilter(filterAreaState, filterTerritoryState)}>
                            <IconSearch />{' '}
                        </button>
                    </form>
                </div>
            </div>
        );
    } else {
        return (
            <div className={`grid buscadorfiltros--ofertas module-buscadorfiltros ${filterState === true ? 'hide' : 'show'}`}>
                <div className="grid-container">
                    <button id="button-filters" onClick={() => setFilterState((oldState) => !oldState)}>
                        <span className="taglabel">
                            <_btnIcon /> <_btnLabel />
                        </span>
                    </button>
                </div>
                <div className="grid-container">
                    <form className="filters-container">
                        <div className="select-container">
                            <div className="select">
                                <select name="slct" id="slct">
                                    <option selected disabled>
                                        División
                  </option>
                                    <option value="1">Pure CSS</option>
                                    <option value="2">No JS</option>
                                    <option value="3">Nice!</option>
                                </select>
                                <label className="taglabel">División</label>
                            </div>
                            <div className="select">
                                <select name="slct" id="slct">
                                    <option selected disabled>
                                        Área funcional
                  </option>
                                    <option value="1">Pure CSS</option>
                                    <option value="2">No JS</option>
                                    <option value="3">Nice!</option>
                                </select>
                                <label className="taglabel">Área funcional</label>
                            </div>
                            <div className="select">
                                <select name="slct" id="slct">
                                    <option selected disabled>
                                        País
                  </option>
                                    <option value="1">Pure CSS</option>
                                    <option value="2">No JS</option>
                                    <option value="3">Nice!</option>
                                </select>
                                <label className="taglabel">País</label>
                            </div>
                        </div>
                        <button type="button" className="search-btn">
                            <IconSearch />{' '}
                        </button>
                    </form>
                </div>
            </div>
        );
    }
};

const changeFilterButton = (e) => {
    let textContent = e.currentTarget.textContent;

    if (textContent === labelMostrarFiltros) {
        e.currentTarget.textContent = labelOcultarFiltros;
    }
    if (textContent === labelOcultarFiltros) {
        e.currentTarget.textContent = labelMostrarFiltros;
    }
};

export default BuscadorFiltros;
