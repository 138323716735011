import React, { useRef, useState, useEffect } from 'react';

import { Link, Button } from 'React/components';

import './Header.scss';
import './NavSecondary.scss';
import './NavSubsection.scss';

import LogoFull from 'Images/logos/logo_full.svg';

import { useStickyHeader } from 'React/custom_hooks';

import { ReactComponent as User } from 'Icons/usuario-16.svg';
import { ReactComponent as HamburgerMenuIcon } from 'Icons/hamburgermenu.svg';
import { ReactComponent as HamburgerMenuIconTablet } from 'Icons/hamburgermenu-tablet.svg';
import { ReactComponent as Triangle } from 'Icons/triangleup.svg';
import { ReactComponent as SearchLense } from 'Icons/searchlense.svg';
import { ReactComponent as Plus } from 'Icons/plus-10.svg';
import { ReactComponent as IconExternal } from 'Icons/linkexternal.svg';
import { ReactComponent as IconClose } from 'Icons/cross.svg';
import { ReactComponent as ArrowRight } from 'Icons/arrow_circled.svg';

import IconsMap from './_iconsMap';

import { ReactComponent as Chevron_L } from 'Icons/chevron_down-18.svg';

const Header = ({ isVisible, stickyHeader, menu, areaClientesLabel, areaClientesLink, contactLabel, contactLink, changeLanguageLabel, changeLanguageLink, culture, searchLabel, dropdownMenuPurpose, dropdownMenuSoluciones, dropdownMenuShareholders, labelPortada, labelCountries, labelCountriesMenu, logoLink }) => {
    const [searchTerm, setSearchTerm] = useState('');

    const searchInput = useRef(null);
    const searchInputTestStand = useRef(null);

    const swipeTargetHeader = React.createRef();
    const desktopNav = React.createRef();
    const mobileNav = React.createRef();
    const navDrawer = React.createRef();
    const countriesRef = React.createRef();
    const searchRef = React.createRef();

    const headerRef = useRef();

    //var stockTicker = "https://ifbaspst.infobolsanet.com/ifblayout2/Layout.aspx?layout=MiniHome&client=Acciona2020";
    var stockTicker = "https://asp.infobolsanet.com/ifblayout/Layout.aspx?layout=MiniHome&client=Acciona2020";

    if (culture === "en") {
        stockTicker = stockTicker + "&lang=en";
    }

    function toggleMobileSubsection(event) {
        event.currentTarget.classList.toggle('active');

        document.querySelectorAll('.subsection__toggle').forEach(function (item) {
            if (item !== event.currentTarget) {
                item.classList.remove('active');
            }
        });
    }

    const onHover = (event) => {
        const navSubsections = [...document.querySelectorAll('.nav_sub_section')];

        navSubsections.forEach(function (item) {
            if (item.dataset.section === event.currentTarget.dataset.sectionId) {
                item.dataset.isVisible = true;
                item.classList.add('is-visible');
                navDrawer.current.dataset.sectionActive = item.dataset.section;
                navDrawer.current.dataset.isVisible = true;
                navDrawer.current.classList.add('is-visible');
            }

            if (item.dataset.section !== event.currentTarget.dataset.sectionId) {
                item.dataset.isVisible = false;
                item.classList.remove('is-visible');
            }

            if (event.currentTarget.dataset.children === 'no') {
                navDrawer.current.dataset.isVisible = false;
                navDrawer.current.classList.remove('is-visible');
            }
        });
    };

    const notHover = (event) => {
        const navSubsections = [...document.querySelectorAll('.nav_sub_section')];

        navSubsections.forEach(function (item) {
            if (item.dataset.section !== event.currentTarget.dataset.sectionId) {
                item.dataset.isVisible = false;
                item.classList.remove('is-visible');
            }
        });
    };

    const toggleSubsectionVisibility = () => {
        navDrawer.current.dataset.isVisible = false;
        navDrawer.current.classList.remove('is-visible');
    };

    const openMenu = () => {
        mobileNav.current.dataset.isVisible = true;
        document.querySelector('.app--layout').dataset.canScroll = false;
    };

    const closeMenu = () => {
        mobileNav.current.dataset.isVisible = false;
        document.querySelector('.app--layout').dataset.canScroll = true;
    };

    const openCountriesOverlay = () => {
        countriesRef.current.dataset.isVisible = true;
    };

    const closeCountriesOverlay = () => {
        countriesRef.current.dataset.isVisible = false;
    };

    const openSearchOverlay = () => {
        searchRef.current.dataset.isVisible = true;
    };

    const closeSearchOverlay = () => {
        searchRef.current.dataset.isVisible = false;
    };

    useEffect(() => {
        if (isVisible) searchInput.current.focus();
    }, [isVisible]);

    const handleInput = (e) => {
        setSearchTerm(e.target.value);

        const input = searchInput.current;
        const testStand = searchInputTestStand.current;

        // measure the length of the input text and update input width
        testStand.innerText = searchTerm;
        input.style.width = `calc(2em + ${testStand.getBoundingClientRect().width}px)`;
    };

    const handleSearchSubmit = (event) => {
        event.preventDefault();
        var searchText = event.target.search.value;

        window.location.href = `/${culture === 'es' ? "es/resultados" : "results"}?search=${searchText}`;

    };

    const countriesList = [
        { label: culture === 'es' ? 'Australia' : 'Australia', path: 'https://www.acciona.com.au/' },
        { label: culture === 'es' ? 'Canadá' : 'Canada', path: 'https://www.acciona.ca/' },
        { label: 'EEUU', path: 'https://www.acciona.us/' },
        { label: culture === 'es' ? 'México' : 'Mexico', path: 'https://www.acciona-mx.com/' },
        { label: 'Chile', path: 'https://www.acciona.cl/' },
        { label: 'Brasil', path: 'https://www.acciona.com.br/' },
        { label: culture === 'es' ? 'Oriente Medio' : 'Middle East', path: 'https://www.acciona-me.com/' },
        { label: culture === 'es' ? 'Noruega' : 'Norway', path: 'https://www.acciona.no/' }
    ];

    useStickyHeader(headerRef, stickyHeader);

    return (
        <>
            <header ref={headerRef} className="page-header grid" id="header">
                <a className="logo-home-link" href={logoLink}>
                    <img className="logo" src={LogoFull} alt="Logo Acciona" title="Logo Acciona" />
                </a>

                <div className="header__row header__top">
                    <p className="stock-ticker">
                        {
                            /*    <Triangle width={12} height={12} />
                                <span>(IBEX 35) 46,1 0,7%</span>*/
                        }
                        <iframe className="ticker-iframe" scrolling="no" src={stockTicker} />
                    </p>
                    <div className="header-right">
                        <Button type="link" className="country-selector" onClick={openCountriesOverlay}>
                            <span>{labelCountries}</span>
                            <Plus width="10px" height="10px" viewBox="0 0 10 10" />
                        </Button>
                        <span className="clientes-touch">
                            <a href={areaClientesLink}>
                                <User viewBox="0 1 17 17" />
                                {areaClientesLabel}
                            </a>
                        </span>
                    </div>
                    <HamburgerMenuIcon className="icon icon__hamburger icon__hamburger--mobile" onClick={openMenu} />
                    <HamburgerMenuIconTablet className="icon icon__hamburger  icon__hamburger--tablet" onClick={openMenu} />

                    <nav className="nav_secondary" aria-label="menú de navegación secundaria" role="navigation">
                        <a href={contactLink}>{contactLabel}</a>
                        <a href={changeLanguageLink}>{changeLanguageLabel}</a>
                        <a href={areaClientesLink}>
                            <User viewBox="0 1 17 17" />
                            {areaClientesLabel}
                        </a>
                    </nav>

                    <button className="open-search-form" onClick={openSearchOverlay} aria-label="open search form">
                        <SearchLense />
                    </button>
                </div>

                <div className="header__row header__bottom">
                    <nav
                        ref={desktopNav}
                        className="nav__main nav__main--desktop"
                        role="navigation"
                        aria-label="Navegación principal para escritorio"
                    >
                        <ul className="nav_links">
                            {menu.map((item, idx) => {
                                return (
                                    <a
                                        className="link link-lvl-0"
                                        href={item.url}
                                        onMouseOut={notHover}
                                        onMouseOver={onHover}
                                        data-children={item.subItemsList.length > 0 ? "has-children" : "no"}
                                        data-section-id={culture === 'es' ? item.url.split("/")[2] : item.url.split("/")[1]}
                                    >
                                        {item.name}
                                        {item.subItemsList.length > 0 &&
                                            <Chevron_L />
                                        }
                                    </a>
                                );
                            })}
                            {/*<a
                                className="link link-lvl-0"
                                href="/es/nuestro-proposito/"
                                onMouseOut={notHover}
                                onMouseOver={onHover}
                                data-children="has-children"
                                data-section-id="sobre_acciona"
                            >
                                Nuestro propósito
                <Chevron_L />
                            </a>
                            <a
                                className="link link-lvl-0"
                                href="/soluciones"
                                onMouseOut={notHover}
                                onMouseOver={onHover}
                                data-children="has-children"
                                data-section-id="soluciones"
                            >
                                Soluciones
                <Chevron_L />
                            </a>
                            <a
                                className="link link-lvl-0"
                                href="/proyectos"
                                data-section-id="proyectos"
                                data-children="no"
                                onMouseOut={notHover}
                                onMouseOver={onHover}
                            >
                                Proyectos
              </a>
                            <a
                                className="link link-lvl-0"
                                href="/accionistas_e_inversores"
                                data-children="has-children"
                                onMouseOut={notHover}
                                onMouseOver={onHover}
                                data-section-id="accionistas_e_inversores"
                            >
                                Accionistas e inversores
                <Chevron_L />
                            </a>
                            <a
                                className="link link-lvl-0"
                                href="/actualidad"
                                data-children="no"
                                data-section-id="actualidad"
                                onMouseOut={notHover}
                                onMouseOver={onHover}
                            >
                                Actualidad
              </a>*/}
                        </ul>
                    </nav>

                    <nav
                        className="nav__main nav__main--mobile"
                        ref={mobileNav}
                        data-is-visible="false"
                        role="navigation"
                        aria-label="Navegación principal para móvil"
                    >
                        <svg width="18" height="18" className="close-x" onClick={closeMenu}>
                            <path d="M10 2v6h6v1h-6v6H9V9H3V8h6V2h1z" fill="#1F140F" fillRule="evenodd"></path>
                        </svg>
                        <ul className="nav_links">

                            <button
                                data-section={culture === 'es' ? menu[0].url.split("/")[2] : menu[0].url.split("/")[1]}
                                type="link"
                                data-is-open="false"
                                onClick={toggleMobileSubsection}
                                className="button taglabel button--md type__base subsection__toggle"
                            >
                                {menu[0].name}
                                <Chevron_L />
                            </button>
                            {menu[0].subItemsList.length > 0 &&
                                <div data-section={culture === 'es' ? menu[0].url.split("/")[2] : menu[0].url.split("/")[1]} className="nav_sub_section lvl-1" data-subsection-visible="false">
                                    {menu[0].subItemsList.map((subItem, idx) => {
                                        return (
                                            <a className="link link-lvl-1" href={subItem.url}>
                                                {subItem.name}
                                            </a>
                                        );
                                    })}
                                    <a className="link link-lvl-0" href={menu[0].url}>
                                        {labelPortada} {menu[0].name}
                                    </a>
                                </div>
                            }

                            <button
                                type="link"
                                data-section={culture === 'es' ? menu[1].url.split("/")[2] : menu[1].url.split("/")[1]}
                                data-is-open="false"
                                onClick={toggleMobileSubsection}
                                className="button taglabel button--md type__base subsection__toggle"
                            >
                                {menu[1].name}
                                <Chevron_L />
                            </button>
                            <div data-section="soluciones" className="nav_sub_section lvl-1" data-subsection-visible="false">
                                {menu[1].subItemsList.map((item, idx) => {
                                    return (
                                        <a className="link link-lvl-1" href={item.url}>
                                            {IconsMap[item.icon]}
                                            {item.name}
                                        </a>
                                    );
                                })}
                                <a className="link link-lvl-0" href={menu[1].url}>
                                    {labelPortada} {menu[1].name}
                                </a>
                            </div>
                            <a className="link link-lvl-0" href={menu[2].url}>
                                {menu[2].name}
                            </a>

                            <button
                                type="link"
                                onClick={toggleMobileSubsection}
                                data-is-open="false"
                                data-section={culture === 'es' ? menu[3].url.split("/")[2] : menu[3].url.split("/")[1]}
                                className="button taglabel button--md type__base subsection__toggle"
                            >
                                {menu[3].name}
                                <Chevron_L />
                            </button>
                            {menu[3].subItemsList.length > 0 &&
                                <div
                                    data-section={culture === 'es' ? menu[3].url.split("/")[2] : menu[3].url.split("/")[1]}
                                    className="nav_sub_section lvl-1"
                                    data-subsection-visible="false"
                                >
                                    {menu[3].subItemsList.map((item, idx) => {
                                        return (
                                            <a className="link link-lvl-1" href={item.url}>
                                                {item.name}
                                            </a>
                                        );
                                    })}
                                    <a className="link link-lvl-0" href={menu[3].url}>
                                        {labelPortada} {menu[3].name}
                                    </a>
                                </div>
                            }
                            <a className="link link-lvl-0" href={menu[4].url}>
                                {menu[4].name}
                            </a>
                        </ul>
                        <div className="nav__mobile-footer">
                            <div className="nav_secondary">
                                <a href={contactLink} className="title--xs">
                                    {contactLabel}
                                </a>
                                <a href={changeLanguageLink} className="title--xs">
                                    {changeLanguageLabel}
                                </a>
                            </div>
                        </div>
                    </nav>
                </div>
                <>
                    <div ref={navDrawer} className="nav nav__drawer" data-section-active="" data-is-visible="false">
                        <nav
                            data-is-visible="false"
                            data-order="1"
                            className="nav_sub_section"
                            data-section={culture === 'es' ? menu[0].url.split("/")[2] : menu[0].url.split("/")[1]}
                            role="navigation"
                            aria-label={menu[0].name}
                        >
                            <div className="drawer__header grid">
                                <p className="drawer__header--title title--m">{menu[0].featuredPhrase}</p>
                                <a href={menu[0].url} title={menu[0].name} className="section-home-link">
                                    <ArrowRight viewBox="0 0 94 94" />
                                </a>
                            </div>
                            <div className="section-links">
                                <div className="subsection-box">
                                    {menu[0].subItemsList.map((item, idx) => {
                                        if (item.subItemsList.length === 0) {
                                            return (
                                                <a className="link" href={item.url}>
                                                    {item.name}
                                                </a>
                                            );
                                        }
                                    })}
                                </div>
                                {menu[0].subItemsList.map((item, idx) => {
                                    if (item.subItemsList.length > 0) {
                                        return (
                                            <div className="subsection-box">
                                                <a className="link" href={item.url}>
                                                    {item.name}
                                                </a>
                                                {item.subItemsList.map((item, idx) => {
                                                    return (
                                                        <a className="link link-lvl-1" href={item.url}>
                                                            {item.name}
                                                        </a>
                                                    );
                                                })}
                                            </div>
                                        );
                                    }
                                })}
                            </div>
                        </nav>
                        <nav
                            data-is-visible="false"
                            data-order="2"
                            className="nav_sub_section"
                            data-section={culture === 'es' ? menu[1].url.split("/")[2] : menu[1].url.split("/")[1]}
                            role="navigation"
                            aria-label={menu[1].name}
                        >
                            <div className="drawer__header grid">
                                <p className="drawer__header--title title--m">
                                    {menu[1].featuredPhrase}
                                </p>
                                <a href={menu[1].url} title={menu[1].name} className="section-home-link">
                                    <ArrowRight viewBox="0 0 94 94" />
                                </a>
                            </div>
                            <div className="section-links">
                                {menu[1].subItemsList.map((item, idx) => {
                                    return (
                                        <a className="link" href={item.url}>
                                            {IconsMap[item.icon]}
                                            {item.name}
                                        </a>
                                    );
                                })}
                            </div>
                        </nav>
                        <nav
                            data-is-visible="false"
                            data-order="4"
                            className="nav_sub_section"
                            data-section={culture === 'es' ? menu[3].url.split("/")[2] : menu[3].url.split("/")[1]}
                            role="navigation"
                            aria-label={menu[3].name}
                        >
                            <div className="drawer__header grid">
                                <p className="drawer__header--title title--m">{menu[3].featuredPhrase}</p>
                                <a href={menu[3].url} title={menu[3].name} className="section-home-link">
                                    <ArrowRight viewBox="0 0 94 94" />
                                </a>
                            </div>
                            <div className="section-links">
                                {menu[3].subItemsList.map((item, idx) => {
                                    if (item.subItemsList.length > 0) {
                                        return (
                                            <div className="subsection-box">
                                                <a className="link" href={item.url}>
                                                    {item.name}
                                                </a>
                                                {item.subItemsList.map((item, idx) => {
                                                    return (
                                                        <a className="link link-lvl-1" href={item.url}>
                                                            {item.name}
                                                        </a>
                                                    );
                                                })}
                                            </div>
                                        );
                                    }
                                })}
                                <div className="subsection-box">
                                    {menu[3].subItemsList.map((item, idx) => {
                                        if (item.subItemsList.length === 0) {
                                            return (
                                                <a className="link" href={item.url}>
                                                    {item.name}
                                                </a>
                                            );
                                        }
                                    })}
                                </div>
                            </div>
                        </nav>
                    </div>
                    <div
                        className="drawer-overlay"
                        onClick={toggleSubsectionVisibility}
                        onMouseOver={toggleSubsectionVisibility}
                    ></div>
                </>
            </header>
            <>
                <div className={'bottom_drawer grid countries_overlay'} ref={countriesRef} data-is-visible="false">
                    <div className="header" ref={swipeTargetHeader}>
                        <h3 className="title title--xs">
                            {labelCountriesMenu}
                            <IconClose width="24" height="24" viewBox="0 0 16 16" onClick={closeCountriesOverlay} />
                        </h3>
                    </div>
                    <div className="body">
                        {countriesList.map((country, idx) => (
                            <a className="title--m" href={country.path} target='_blank' key={`country-item-${idx}`}>
                                {country.label}
                                <IconExternal width="18" height="18" viewBox="0 0 18 18" />
                            </a>
                        ))}
                    </div>
                </div>

                <div data-is-visible="false" className="module grid search_overlay" ref={searchRef}>
                    <div className="header">
                        <h3 className="title title--xs">
                            <IconClose onClick={closeSearchOverlay} />
                        </h3>
                    </div>
                    <div className="body">
                        <form className="search-input-wrapper" onSubmit={handleSearchSubmit}>
                            <input onChange={handleInput} className="search-input" name="search" ref={searchInput} placeholder={searchLabel} />
                            <button className="search-submit" type="submit">
                                <SearchLense />
                            </button>
                        </form>
                        <div className="search-input-test-stand" ref={searchInputTestStand}></div>
                    </div>
                </div>
            </>
        </>
    );
};

export default Header;
