import React, { useState, useEffect, useContext } from 'react';
import { PropTypes as pt } from 'prop-types';

import { ReactComponent as CloseIcon } from 'Icons/cross.svg';
import './Lightbox.scss';
import uiContext from 'Data/_uiContext';

const Lightbox = ({ isVisible, children, closeMethod }) => {
    const [boxVisibility, setBoxVisibility] = useState(isVisible);

    const handleClose = () => {
        if (closeMethod) {
            closeMethod();
            document.querySelector('.app--layout').dataset.canScroll = true;
        } else {
            setBoxVisibility(false);
        }
    };

    useEffect(() => {
        if (boxVisibility) {
            document.querySelector('.app--layout').dataset.canScroll = false;
        } else {
            document.querySelector('.app--layout').dataset.canScroll = true;
        }
    }, [boxVisibility]);

    return (
        <div className="grid component lightbox" data-visibility={boxVisibility}>
            <div className="lightbox__box grid">
                <button className="lightbox__hide__btn" onClick={handleClose}>
                    <CloseIcon />
                </button>
                <div className="content-wrapper">{children}</div>
            </div>
        </div>
    );
};

Lightbox.propTypes = {
    isVisible: pt.bool,
    closeMethod: pt.func,
    children: pt.node.isRequired,
};

export default Lightbox;
