import React, { useEffect, useState } from 'react';

import { PropTypes as pt } from 'prop-types';
import supercluster from 'points-cluster';
import GoogleMapReact from 'google-map-react';
import { rndKeyGen } from 'React/helpers';

import darkMapConfig from './googleMap.style';

const setMapStyle = (map, maps) => {
    const newMapStyle = new maps.StyledMapType(darkMapConfig);
    map.mapTypes.set('dark_map', newMapStyle);
    map.setMapTypeId('dark_map');
};

// MAP COMPONENT
const Map = ({ pointsList, changeSelectedPoint, mapCenter, changeMapCenter, mapZoomValue, changeZoomValue, culture }) => {
    const [clusters, setClusters] = useState([]);
    const [mapZoom, setMapZoom] = useState(3);

    useEffect(() => {
        // recalculate clusters if markers list or zoom level changes
        const clusters = supercluster(pointsList);
        const r = clusters({
            bounds: {
                nw: { lat: 180, lng: -180 },
                se: { lat: -180, lng: 180 },
            },
            zoom: mapZoomValue,
        });
        setClusters(r);
    }, [pointsList, mapZoomValue]);

    const gmrProps = {
        bootstrapURLKeys: { key: 'AIzaSyCZq9Kx8o0pEDos9tsE4JENXT0nx2_B3v4', language: culture },
        center: mapCenter,
        yesIWantToUseGoogleMapApiInternals: true,
        zoom: mapZoomValue,
        options: {
            minZoom: 3,
            disableDefaultUI: false,
            draggableCursor: 'pointer',
            scrollwheel: false,
            backgroundColor: 'rgba(0, 0, 0, .9)',
        },
        onChildClick: (key, childProps) => {
            if (childProps.pointType === 'cluster') {
                const { lat, lng } = childProps;

                changeMapCenter({ lat, lng });
                changeZoomValue(mapZoom + 3);
            }

            if (childProps.projectId) changeSelectedPoint(childProps.projectId);
        },
        onGoogleApiLoaded: ({ map, maps }) => {
            setMapStyle(map, maps);
            map.addListener('idle', function () {
                const zoom = map.getZoom();
                const center = map.getCenter().toJSON();
                setMapZoom(zoom);
                changeZoomValue(zoom);
                changeMapCenter(center);
            });
        },
    };

    return (
        <div id="gmrWrapper">
            <GoogleMapReact {...gmrProps} defaultZoom={2}>
                {clusters.map(({ numPoints, wx, wy, points }) =>
                    numPoints === 1 ? (
                        <Marker
                            projectId={points[0].id}
                            lat={points[0].lat}
                            lng={points[0].lng}
                            selected={points[0].selected}
                            key={rndKeyGen()}
                        />
                    ) : (
                            <ClusterMarker count={numPoints} pointType="cluster" lat={wy} lng={wx} key={rndKeyGen()} />
                        )
                )}
            </GoogleMapReact>
        </div>
    );
};

Map.propTypes = {
    pointsList: pt.array.isRequired,
    mapCenter: pt.shape({
        lat: pt.number,
        lng: pt.number,
    }).isRequired,
    mapZoomValue: pt.number.isRequired,
    changeZoomValue: pt.func.isRequired,
    changeMapCenter: pt.func.isRequired,
    changeSelectedPoint: pt.func.isRequired,
};

// MARKER COMPONENTS
const Marker = ({ selected }) => {
    const selectedPointClass = selected ? 'selected' : '';
    return (
        <div className={`project-marker ${selectedPointClass}`}>
            <div className="project-marker__inner-marker"></div>
        </div>
    );
};

const ClusterMarker = ({ count }) => {
    return (
        <div className="cluster-marker">
            <div className="cluster-marker__inner-marker">
                <span className="body--s">{count}</span>
            </div>
        </div>
    );
};

export default Map;
