// button.component.js
import React from 'react';
import './styles.scss';
//import { Link } from 'React/components';

import { ReactComponent as LinkedinIcon } from 'Icons/linkedin.svg';
import { ReactComponent as FacebookIcon } from 'Icons/facebook.svg';
import { ReactComponent as TwitterIcon } from 'Icons/twitter.svg';
import { ReactComponent as WhatsappIcon } from 'Icons/whatsapp.svg';



const ShareSocial = ({ title, seoTitle, socialShareTextTwiter, socialShareAccionaTwitterAccount }) => {
    if (typeof window !== 'undefined') {
        var url = "https://api.whatsapp.com/send?text=" + seoTitle + " " + window.location.href;
        return (
            <div className="module grid module-sharesocial">
                <div className="module-sharesocial-container grid full-width">
                    <h2 className="title--s">{title}</h2>
                    <nav className="sharesocial-nav">
                        <a href="javascript:;" className="st_linkedin_custom" st_title={seoTitle} st_image=""><LinkedinIcon /></a>
                        <a href="javascript:;" className="st_facebook_custom" st_title={seoTitle} ><FacebookIcon /></a>
                        <a href="javascript:;" className="st_twitter_custom" st_title={socialShareTextTwiter ? socialShareTextTwiter : seoTitle} st_via={socialShareAccionaTwitterAccount ? socialShareAccionaTwitterAccount : ''} ><TwitterIcon /></a>
                        <a href={url} className="link mobile-visible" target="_blank"><WhatsappIcon /></a>
                    </nav>
                </div>
            </div>
        );
    }
    else {
        return (
            <>
            </>
        ); 
    }
};


export default ShareSocial;
