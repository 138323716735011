// button.component.js
import React, { setState, useState, useEffect } from 'react';
import { TagTitle, EnlaceAgrupador } from 'React/components';

import './Styles.scss';


const Agenda = ({ model, dates, datesPast, culture, addTo, addCalendar,
    yearName, finishEvents, events }) => {
    const [activeEventsState, setActiveEventsState] = useState(false);
    const [finishedEventsState, setFinishedEventsState] = useState(false);
    const [yearFilterState, setYearFilterState] = useState('2020');
    const { isModule, anchor, subModules } = model;

    const monthNamesEN = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const monthNamesES = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
        "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ];    

    useEffect(() => {
        var actualDates = [];
        var finishedDates = [];

        subModules.map((year, idx) => {
            year.subModules.map((event, idx) => {
                var date = new Date(event.date);
                event.date = date;
                var tomorrow = new Date();
                tomorrow.setDate(tomorrow.getDate() - 1);
                if (date < tomorrow) {
                    if (String(date.getFullYear()) === yearFilterState) { finishedDates.push(event); }
                }
                else {
                    actualDates.push(event);
                }
            });
        });
        actualDates.sort((a, b) => a.date - b.date);
        finishedDates.sort((a, b) => b.date - a.date);

        setActiveEventsState(actualDates);
        setFinishedEventsState(finishedDates);

    }, []);

    const setFilter = (e) => {
        setYearFilterState(e.target.value);

        var finishedDates = [];

        subModules.map((year, idx) => {
            year.subModules.map((event, idx) => {
                var date = new Date(event.date);
                event.date = date;
                var tomorrow = new Date();
                tomorrow.setDate(tomorrow.getDate() - 1);
                if (date < tomorrow && String(date.getFullYear()) === e.target.value) {
                    finishedDates.push(event);
                }
            });
        });
        finishedDates.sort((a, b) => b.date - a.date);

        setFinishedEventsState(finishedDates);
    };

    function yyyymmdd(date) {
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        var d = date.getDate();
        var mm = m < 10 ? '0' + m : m;
        var dd = d < 10 ? '0' + d : d;

        var tomorrow = date;
        tomorrow.setDate(tomorrow.getDate() + 1);

        var y2 = tomorrow.getFullYear();
        var m2 = tomorrow.getMonth() + 1;
        var d2 = tomorrow.getDate();
        var mm2 = m2 < 10 ? '0' + m2 : m2;
        var dd2 = d2 < 10 ? '0' + d2 : d2;

        return '' + y + mm + dd + '/' + y2 + mm2 + dd2;
    }


    if (typeof window !== 'undefined') {        
        return (
            <div className="template-agenda grid">
                {activeEventsState &&
                    <div className="grid-container agenda-activo" id={anchor}>
                        {activeEventsState.map((item, idx) => {
                            const { title, place, text, link, date } = item;

                            var dateObj = new Date(date);
                            var day = dateObj.getDate();
                            var year = dateObj.getFullYear();
                            if (culture != 'es') {
                                var month = monthNamesEN[dateObj.getMonth()] + " " + year;
                            } else {
                                var month = monthNamesES[dateObj.getMonth()] + " " + year;
                            }



                            var dateFormated = yyyymmdd(dateObj);
                            var splitDate = dateFormated.split("/");

                            var path = [
                                { caption: addTo + ' Google Calendar', link: 'https://www.google.com/calendar/event?action=TEMPLATE&text=' + title + '&dates=' + dateFormated + '&details=' + text + '&location=' + place, type: 'external', target: '_blank' },
                                { caption: addTo + ' Apple Calendar', link: 'webcal://www.acciona.com/ICS?title=' + title + '&place=' + place + '&text=' + text + '&date=' + splitDate[0], type: 'external', target: '_blank' },
                                { caption: addTo + ' Outlook Calendar', link: '/ICS?title=' + title + '&place=' + place + '&text=' + text + '&date=' + splitDate[0], type: 'external', target: '_blank' },
                                { caption: addTo + ' Yahoo Calendar', link: 'https://calendar.yahoo.com/?v=60&title=' + title + '&st=' + splitDate[0] + '&et=' + splitDate[1] + '&desc=' + text + '&in_loc=' + place + '&dur=0', type: 'external', target: '_blank' }
                            ];

                            return (
                                <div className="agenda__item" key={`key-${idx}`}>
                                    <div className="agenda__day">
                                        <div className="day cifras--l">{day}</div>
                                        <div className="month title--xs">{month}</div>
                                    </div>
                                    <div className="agenda__content">
                                        <h3 className="title--s">{title}</h3>
                                        <div className="agenda__container">
                                            <div className="title--xs">{place}</div>

                                            {path &&
                                                <div className="title--xs"><EnlaceAgrupador links={path} box_type="subscribe" addCalendar={addCalendar} /></div>
                                            }

                                        </div>
                                        {text &&
                                            <p className="body--m">{text}</p>
                                        }
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                }
                {finishedEventsState &&
                    <div className="grid-container">
                        <div className="agenda-header ">
                        <TagTitle title={`${finishEvents} ${yearFilterState} (${finishedEventsState.length} ${events})`} />
                            <div className="select_input">
                                <div className="select_wrapper">
                                    <select className="body--m" onChange={setFilter}>
                                        <option value="2020" className="body--m">2020</option>
                                        <option value="2019" className="body--m">2019</option>
                                    </select>
                                <label className="taglabel">{yearName}</label>
                                </div>
                            </div>
                        </div>
                        <div className="agenda-pasado grid-container">
                            {finishedEventsState.map((item, idx) => {
                                const { title, place, date } = item;

                                var dateObj = new Date(date);
                                var day = dateObj.getDate();
                                var year = dateObj.getFullYear();
                                if (culture != 'es') {
                                    var month = monthNamesEN[dateObj.getMonth()];
                                } else {
                                    var month = monthNamesES[dateObj.getMonth()];
                                }


                                return (
                                    <div className="agenda__item" key={`key-${idx}`}>
                                        <div className="agenda__content">
                                            <h3 className="title--s">{title}</h3>
                                            <div className="agenda__container">
                                                <div className="title--xs">{place}</div>
                                                <div className="title--xs">{`${day} ${month} ${year}`}</div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                }

            </div>
        );
    }
    else {
        return (
            <>
            </>
        );
    }
};

export default Agenda;