import React, { useState } from 'react';

import { rndKeyGen } from 'React/helpers';
import './Styles.scss';

const SelectInput = ({ label, options, defaultValue, name, errorMsg, event }) => {
    const [value, setValue] = useState('');

    const onChangeEvent = (value) => {
        setValue(value);
        event(value);
    };

    return (
        <div className="select_input">
            <div className="select_wrapper">
                <label className="taglabel">{label}</label>
                <select
                    className="body--m"
                    value={value}
                    onChange={(e) => onChangeEvent(e.target.value)}
                    name={name}
                >
                    <option
                        className="body--m"
                        value=""
                        key={rndKeyGen()}
                    >
                        {defaultValue}
                    </option>
                    {options.map((option, idx) => (
                        <option
                            className="body--m"
                            value={option.toLowerCase()}
                            key={rndKeyGen()}
                        >
                            {option}
                        </option>
                    ))}
                </select>
            </div>
            <span className="error_msg" style={{ marginTop: '23px' }} dangerouslySetInnerHTML={{ __html: errorMsg }}></span>
        </div>
    );
};

export default SelectInput;
