import React, { useState, useEffect } from 'react';
import { Link } from 'React/components';

import './styles.scss';
import { ReactComponent as IconWind } from 'Icons/energia-viento.svg';
import { ReactComponent as ArrowRight } from 'Icons/arrow_circled.svg';
import IconsMap from './_iconsMap';

const ResultadosProyectos = ({ frames }) => {

    const refList = frames.map(frame => React.createRef());

    const onHover = (event) => {
        const { refId } = event.currentTarget.dataset;
        refList[parseInt(refId)].current.classList.add('is-hovered');
    };

    const notHover = (event) => {
        const { refId } = event.currentTarget.dataset;
        refList[parseInt(refId)].current.classList.remove('is-hovered');
    };

    if (typeof window !== 'undefined') {

        return (
            <div className="grid module">
                <div className="grid-container module-resultados">
                    {frames.map(({ indexImage, legacyHeader, headerImage, title, _contentPath, country, region, solution, isInstalation }, idx) => {
                        if (title) {
                            //const imageUrl = useWindowWidth() >= 768 ? desktopImage : mobileImage;
                            var imageUrl = headerImage ? headerImage.url : legacyHeader.replace("//media/", "/media/");

                            return (
                                <div className="module-resultados__item white-background" key={`image-frame-${idx}`} ref={refList[idx]}>
                                    {!isInstalation &&
                                        <div className="resultados__image" style={{ backgroundImage: `url(${imageUrl ? imageUrl : ''})` }}></div>
                                    }
                                    {isInstalation &&
                                        <div className="resultados__image" style={{ backgroundImage: `url(${imageUrl ? imageUrl : ''})` }}></div>
                                    }
                                    <div className="resultados__container">
                                        <div>
                                            <h2 className="title--m">
                                                {!isInstalation &&
                                                    <a data-ref-id={idx} onMouseOut={notHover} onMouseOver={onHover} href={_contentPath ? _contentPath : '#'}>{title ? title : ''}</a>
                                                }
                                                {isInstalation &&
                                                    <>{title ? title : ''}</>
                                                }
                                            </h2>
                                            <div className="taglabel"><a href={solution.url} >{IconsMap[solution.icon]}{solution.name}</a>
                                                {(region || country) &&
                                                    <span>{region ? region : ''}{country && region ? `, ${country}` : country}</span>
                                                }
                                            </div>
                                        </div>
                                        {!isInstalation &&
                                            <div className="cta">
                                                <a href={_contentPath} data-ref-id={idx} onMouseOut={notHover} onMouseOver={onHover}><ArrowRight viewBox="0 0 94 94" /></a>
                                            </div>
                                        }
                                    </div>
                                </div>
                            );
                        }
                    })}
                </div>
            </div>
        );
    }
    else {
        return (
            <div className="grid module">
                <div className="grid-container module-resultados">
                </div>
            </div>
        );
    }
};

const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    return windowWidth;
};


export default ResultadosProyectos;
