import React from 'react';
import { PropTypes as pt } from 'prop-types';
import './styles.scss';

const InputRangeSlider = React.forwardRef(
    ({ activeFrame, framesCount, frameDescription, changeHandler, noIndicator, whiteThumb }, ref) => {
        const handleSliderChange = (event) => {
            changeHandler(event.target.value);
        };

        return (
            <div ref={ref} className="component input-range-slider grid slow--y">
                {framesCount > 1 && (
                    <input
                        aria-label="slider control"
                        className={whiteThumb ? 'white-thumb' : null}
                        type="range"
                        min={1}
                        max={framesCount}
                        value={activeFrame}
                        step={1}
                        onChange={handleSliderChange}
                        onInput={handleSliderChange}
                    />
                )}
                {framesCount === 1 && <hr />}
                {frameDescription && (
                    <div className="description slow--y">
                        <div className="body--m slow--y" dangerouslySetInnerHTML={{ __html: frameDescription ? frameDescription : <span>&nbsp;</span>}}/>
                    </div>
                )}

                {!noIndicator && (
                    <p className="frame-position-indicator slow--y">
                        <span className="active">{activeFrame < 10 ? `0${activeFrame}` : activeFrame}</span>
                        <span className="last">{framesCount < 10 ? `0${framesCount}` : framesCount}</span>
                    </p>
                )}
            </div>
        );
    }
);

export default InputRangeSlider;
