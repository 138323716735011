import React, { useEffect, useRef } from 'react';

import IconsMap from './_iconsMap';

import './styles.scss';

const SolucionesMosaico = ({ subModules,/*energia, transporte, agua, social, ciudades, inmobiliaria, capital,*/ anchor }) => {
    let moduleRef = React.createRef();
    let energiaRef = useRef();
    let transporteRef = useRef();
    let aguaRef = useRef();
    let socialRef = useRef();
    let ciudadesRef = useRef();
    let inmobiliariaRef = useRef();
    let capitalRef = useRef();

    useEffect(() => {

        let imgEnergia = new Image();
        let imgTransporte = new Image();
        let imgCiudades = new Image();
        let imgSocial = new Image();
        let imgCapital = new Image();
        let imgInmobiliaria = new Image();
        let imgAgua = new Image();

        imgEnergia.src = subModules[0] ? `${subModules[0].image.url}` : '';
        imgTransporte.src = subModules[1] ? `${subModules[1].image.url}` : '';
        imgAgua.src = subModules[2] ? `${subModules[2].image.url}` : '';
        imgSocial.src = subModules[3] ? `${subModules[3].image.url}` : '';
        imgCiudades.src = subModules[4] ? `${subModules[4].image.url}` : '';
        imgInmobiliaria.src = subModules[5] ? `${subModules[5].image.url}` : '';
        imgCapital.src = subModules[6] ? `${subModules[6].image.url}` : '';
        if (typeof window !== 'undefined') {
            // controlamos con esto que la imagen est� cargada y entonces la desvelamos
            subModules[0] ? imgEnergia.onload = function () {
                energiaRef ? energiaRef.classList.add('reveal') : null;
            } : null;

            subModules[1] ? imgTransporte.onload = function () {
                transporteRef ? transporteRef.classList.add('reveal') : null;
            } : null;

            subModules[2] ? imgAgua.onload = function () {
                aguaRef ? aguaRef.classList.add('reveal') : null;
            } : null;

            subModules[3] ? imgSocial.onload = function () {
                socialRef ? socialRef.classList.add('reveal') : null;
            } : null;

            subModules[4] ? imgCiudades.onload = function () {
                ciudadesRef ? ciudadesRef.classList.add('reveal') : null;
            } : null;

            subModules[5] ? imgInmobiliaria.onload = function () {
                inmobiliariaRef ? inmobiliariaRef.classList.add('reveal') : null;
            } : null;

            subModules[6] ? imgCapital.onload = function () {
                capitalRef ? capitalRef.classList.add('reveal') : null;
            } : null;

        }
        if (imgEnergia.complete) imgEnergia.onload();
        if (imgTransporte.complete) imgTransporte.onload();
        if (imgAgua.complete) imgAgua.onload();
        if (imgSocial.complete) imgSocial.onload();
        if (imgCiudades.complete) imgCiudades.onload();
        if (imgInmobiliaria.complete) imgInmobiliaria.onload();
        if (imgCapital.complete) imgCapital.onload();
    });

    return (
        <div ref={moduleRef} className="module grid module-soluciones-mosaico">
            <div className="grid-container">
                <div className="mosaico-row">
                    <div className="mosaico-cell mosaico-cell--1">
                        {subModules[0] &&
                            <a
                                ref={(el) => (energiaRef = el)}
                                href={subModules[0].link ? subModules[0].link.link : subModules[0].solution._contentPath}
                                className="mosaico-item item-1"
                                style={{ backgroundImage: `url(${subModules[0].image.url})` }}
                                target={subModules[0].link ? subModules[0].link.target : ''}
                            >
                                <div className="content">
                                    <div className="content-inner">
                                        <h2 className="title--xs">
                                            {IconsMap[subModules[0].solution.icon]}
                                            {subModules[0].solution.menuText}
                                        </h2>
                                    </div>
                                </div>
                                <div className="hover-content">
                                    {subModules[0].cipher &&
                                        <>
                                            <h2 className="cifras--m">
                                                {subModules[0].cipher}
                                                <span className="title--xs">{subModules[0].description ? subModules[0].description : ''}</span>
                                            </h2>
                                            <h3 className="title--xs">
                                                {IconsMap[subModules[0].solution.icon]}
                                                {subModules[0].solution.menuText}
                                            </h3>
                                        </>
                                    }
                                    {subModules[0].text &&
                                        <>
                                            <h2 className="title--s">{subModules[0].text}</h2>
                                            <h3 className="title--xs">
                                                {IconsMap[subModules[0].solution.icon]}
                                                {subModules[0].solution.menuText}
                                            </h3>
                                        </>
                                    }
                                </div>
                            </a>
                        }

                        <div className="mosaico-cell--half">
                            {subModules[1] &&
                                <a
                                    ref={(el) => (transporteRef = el)}
                                    href={subModules[1].link ? subModules[1].link.link : subModules[1].solution._contentPath}
                                    className="mosaico-item item-2"
                                    style={{ backgroundImage: `url(${subModules[1].image.url})` }}
                                    target={subModules[1].link ? subModules[1].link.target : ''}
                                >
                                    <div className="content">
                                        <div className="content-inner">
                                            <h2 className="title--xs">
                                                {IconsMap[subModules[1].solution.icon]}
                                                {subModules[1].solution.menuText}
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="hover-content">
                                        {subModules[1].cipher &&
                                            <>
                                                <h2 className="cifras--m">
                                                    {subModules[1].cipher}
                                                    <span className="title--xs">{subModules[1].description ? subModules[1].description : ''}</span>
                                                </h2>
                                                <h3 className="title--xs">
                                                    {IconsMap[subModules[1].solution.icon]}
                                                    {subModules[1].solution.menuText}
                                                </h3>
                                            </>
                                        }
                                        {subModules[1].text &&
                                            <>
                                                <h2 className="title--s">{subModules[1].text}</h2>
                                                <h3 className="title--xs">
                                                    {IconsMap[subModules[1].solution.icon]}
                                                    {subModules[1].solution.menuText}
                                                </h3>
                                            </>
                                        }
                                    </div>
                                </a>
                            }
                            {subModules[2] &&
                                <a
                                    ref={(el) => (aguaRef = el)}
                                    href={subModules[2].link ? subModules[2].link.link : subModules[2].solution._contentPath}
                                    className="mosaico-item item-3"
                                    style={{ backgroundImage: `url(${subModules[2].image.url})` }}
                                    target={subModules[2].link ? subModules[2].link.target : ''}
                                >
                                    <div className="content">
                                        <div className="content-inner">
                                            <h2 className="title--xs">
                                                {IconsMap[subModules[2].solution.icon]}
                                                {subModules[2].solution.menuText}
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="hover-content">
                                        {subModules[2].cipher &&
                                            <>
                                                <h2 className="cifras--m">
                                                    {subModules[2].cipher}
                                                    <span className="title--xs">{subModules[2].description ? subModules[2].description : ''}</span>
                                                </h2>
                                                <h3 className="title--xs">
                                                    {IconsMap[subModules[2].solution.icon]}
                                                    {subModules[2].solution.menuText}
                                                </h3>
                                            </>
                                        }
                                        {subModules[2].text &&
                                            <>
                                                <h2 className="title--s">{subModules[2].text}</h2>
                                                <h3 className="title--xs">
                                                    {IconsMap[subModules[2].solution.icon]}
                                                    {subModules[2].solution.menuText}
                                                </h3>
                                            </>
                                        }
                                    </div>
                                </a>
                            }
                        </div>
                    </div>
                    {subModules[3] &&
                        <a
                            href={subModules[3].link ? subModules[3].link.link : subModules[3].solution._contentPath}
                            className="mosaico-cell--2 mosaico-item mosaico-cell item-4"
                            ref={(el) => (socialRef = el)}
                            style={{ backgroundImage: `url(${subModules[3].image.url})` }}
                            target={subModules[3].link ? subModules[3].link.target : ''}
                        >
                            <div className="content">
                                <div className="content-inner">
                                    <h2 className="title--xs">
                                        {IconsMap[subModules[3].solution.icon]}
                                        {subModules[3].solution.menuText}
                                    </h2>
                                </div>
                            </div>
                            <div className="hover-content">
                                {subModules[3].cipher &&
                                    <>
                                        <h2 className="cifras--m">
                                            {subModules[3].cipher}
                                            <span className="title--xs">{subModules[3].description ? subModules[3].description : ''}</span>
                                        </h2>
                                        <h3 className="title--xs">
                                            {IconsMap[subModules[3].solution.icon]}
                                            {subModules[3].solution.menuText}
                                        </h3>
                                    </>
                                }
                                {subModules[3].text &&
                                    <>
                                        <h2 className="title--s">{subModules[3].text}</h2>
                                        <h3 className="title--xs">
                                            {IconsMap[subModules[3].solution.icon]}
                                            {subModules[3].solution.menuText}
                                        </h3>
                                    </>
                                }
                            </div>
                        </a>
                    }
                </div>
            </div>
            <div className="grid-container">
                <div className="mosaico-row">
                    {subModules[4] &&
                        <a
                            ref={(el) => (ciudadesRef = el)}
                            href={subModules[4].link ? subModules[4].link.link : subModules[4].solution._contentPath}
                            className="mosaico-cell-3 mosaico-item item-5"
                            style={{ backgroundImage: `url(${subModules[4].image.url})` }}
                            target={subModules[4].link ? subModules[4].link.target : ''}
                        >
                            <div className="content">
                                <div className="content-inner">
                                    <h2 className="title--xs">
                                        {IconsMap[subModules[4].solution.icon]}
                                        {subModules[4].solution.menuText}
                                    </h2>
                                </div>
                            </div>
                            <div className="hover-content">
                                {subModules[4].cipher &&
                                    <>
                                        <h2 className="cifras--m">
                                            {subModules[4].cipher}
                                            <span className="title--xs">{subModules[4].description ? subModules[4].description : ''}</span>
                                        </h2>
                                        <h3 className="title--xs">
                                            {IconsMap[subModules[4].solution.icon]}
                                            {subModules[4].solution.menuText}
                                        </h3>
                                    </>
                                }
                                {subModules[4].text &&
                                    <>
                                        <h2 className="title--s">{subModules[4].text}</h2>
                                        <h3 className="title--xs">
                                            {IconsMap[subModules[4].solution.icon]}
                                            {subModules[4].solution.menuText}
                                        </h3>
                                    </>
                                }
                            </div>
                        </a>
                    }
                    {subModules[5] &&
                        <a
                            href={subModules[5].link ? subModules[5].link.link : subModules[5].solution._contentPath}
                            className="mosaico-cell-4 mosaico-item item-6"
                            ref={(el) => (inmobiliariaRef = el)}
                            style={{ backgroundImage: `url(${subModules[5].image.url})` }}
                            target={subModules[5].link ? subModules[5].link.target : ''}
                        >
                            <div className="content">
                                <div className="content-inner">
                                    <h2 className="title--xs">
                                        {IconsMap[subModules[5].solution.icon]}
                                        {subModules[5].solution.menuText}
                                    </h2>
                                </div>
                            </div>
                            <div className="hover-content">
                                {subModules[5].cipher &&
                                    <>
                                        <h2 className="cifras--m">
                                            {subModules[5].cipher}
                                            <span className="title--xs">{subModules[5].description ? subModules[5].description : ''}</span>
                                        </h2>
                                        <h3 className="title--xs">
                                            {IconsMap[subModules[5].solution.icon]}
                                            {subModules[5].solution.menuText}
                                        </h3>
                                    </>
                                }
                                {subModules[5].text &&
                                    <>
                                        <h2 className="title--s">{subModules[5].text}</h2>
                                        <h3 className="title--xs">
                                            {IconsMap[subModules[5].solution.icon]}
                                            {subModules[5].solution.menuText}
                                        </h3>
                                    </>
                                }
                            </div>
                        </a>
                    }
                    {subModules[6] &&
                        <a
                            ref={(el) => (capitalRef = el)}
                            href={subModules[6].link ? subModules[6].link.link : subModules[6].solution._contentPath}
                            className="mosaico-cell-5 mosaico-item item-7"
                            style={{ backgroundImage: `url(${subModules[6].image.url})` }}
                            target={subModules[6].link ? subModules[6].link.target : ''}
                        >
                            <div className="content">
                                <div className="content-inner">
                                    <h2 className="title--xs">
                                        {IconsMap[subModules[6].solution.icon]}
                                        {subModules[6].solution.menuText}
                                    </h2>
                                </div>
                            </div>
                            <div className="hover-content">
                                {subModules[6].cipher &&
                                    <>
                                        <h2 className="cifras--m">
                                            {subModules[6].cipher}
                                            <span className="title--xs">{subModules[6].description ? subModules[6].description : ''}</span>
                                        </h2>
                                        <h3 className="title--xs">
                                            {IconsMap[subModules[6].solution.icon]}
                                            {subModules[6].solution.menuText}
                                        </h3>
                                    </>
                                }
                                {subModules[6].text &&
                                    <>
                                        <h2 className="title--s">{subModules[6].text}</h2>
                                        <h3 className="title--xs">
                                            {IconsMap[subModules[6].solution.icon]}
                                            {subModules[6].solution.menuText}
                                        </h3>
                                    </>
                                }
                            </div>
                        </a>
                    }
                </div>
            </div>
        </div>
    );
};

export default SolucionesMosaico;
